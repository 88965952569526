import React from "react"
import Layout from "../components/Layout"
import { Content } from "rbx"
import { Helmet } from "react-helmet"

export default ({ data }) => (
  <Layout>
    <Helmet>
      <title>Privacy</title>
      <meta name="ROBOTS" content="NOINDEX, NOFOLLOW" />
    </Helmet>
    <Content textAlign="left">
      <h1>Privacy</h1>
      <h2>Privacy Policy</h2>
      <p>
        This Privacy Policy applies to the website danlynch.com and associated
        sites (the “Site”). This Privacy Policy describes the information we
        collect on the Site and how Dan Lynch (“we”, “our”, or “us”) uses it,
        the choices you can make about your information and how we protect your
        information.
      </p>

      <h2>Your Privacy, Explained Clearly</h2>
      <p>
        When you sign up to receive the email newsletter, that is the only thing
        your email will be used for — to send you emails, only from Dan Lynch.
        Your email address will not be given or sold to any person or company.
      </p>

      <p>
        When you make a purchase on this website, your information will not be
        given or sold to any person or company.
      </p>

      <p>
        Your privacy is taken seriously. Your information will never be sold or
        used for anything other than the intended purpose (e.g., to make a
        purchase and access your account, join an email newsletter).
      </p>

      <h2>The information we collect and how we use it</h2>

      <p>
        We collect your email address, and sometimes your first name. For
        example, you can subscribe to website updates (or other offerings including free updates, free
        reports, and other information) from the Site by submitting your email
        address. You also may submit comments to the blog on the Site or through
        the contact mechanism on the Site.
      </p>

      <p>
        We generally do not encourage the submission of any personal information
        in these comments or through the contact mechanism other than what we
        expressly require (e.g., name and email address). Information you
        disclose in the comments becomes public information and you should
        exercise caution when deciding to disclose your information in a
        submission.
      </p>

      <p>
        Web servers may automatically capture the following information from
        visitors to the Site, including the name of the domain and host from
        which you access the internet, the internet address of the Site from
        which you enter the Site, the pages you visit on the Site and the amount
        of time you spend on the Site. This visitor information may be used to
        improve the Site.
      </p>

      <h2>Links to other Web sites</h2>
      <p>
        We do not endorse and are not responsible for the information practices
        or privacy policies of any other Web sites that may be linked to or from
        the Site. If you decide to access any other Web site that may be linked
        to or from the Site, you should consult that Web site’s privacy policy.
      </p>

      <h2>Security</h2>
      <p>
        We take reasonable steps to protect personal information from loss and
        misuse. Please keep in mind that no Internet transmission is 100%
        secure. Some email sent to or from the Site may not be secure. Please
        consider this when sending information to us by email.
      </p>

      <h2>Privacy Policy Changes</h2>

      <p>
        Although most changes are likely to be minor, Dan Lynch may change its
        Privacy Policy from time to time, and in Dan Lynch’s sole discretion.
        Dan Lynch encourages visitors to frequently check this page for any
        changes to its Privacy Policy. Your continued use of this site after any
        change in this Privacy Policy will constitute your acceptance of such
        change.
      </p>
    </Content>
  </Layout>
)
