import React from "react"
import { Link } from "gatsby"
import { Navbar } from "rbx"

export default ({ title } = { title: "Home" }) => (
  <Navbar>
    <Navbar.Brand>
      <Navbar.Item
        style={{
          padding: "0px 0px 0px 0px",
        }}
      >
        <Link to={`/`}>{title}</Link>
      </Navbar.Item>
      <Navbar.Burger />
    </Navbar.Brand>
    <Navbar.Menu>
      <Navbar.Segment align="end">
        <Navbar.Item as={Link} to={"/"}>
          home
        </Navbar.Item>
        <Navbar.Item as={Link} to={"/contact"}>
          contact
        </Navbar.Item>
      </Navbar.Segment>
    </Navbar.Menu>
  </Navbar>
)

// export default ({ title } = { title: "Home" }) => (
//   <>
//     <section id="header">
//       <Link to={`/`}>
//         <h3
//           css={css`
//             margin-bottom: ${rhythm(2)};
//             display: inline-block;
//             font-style: normal;
//           `}
//         >
//           {title}
//         </h3>
//       </Link>
//       <Link
//         to={`/about/`}
//         css={css`
//           float: right;
//         `}
//       >
//         About
//       </Link>
//       <Link
//         to={`/contact/`}
//         css={css`
//           float: right;
//         `}
//       >
//         Contact
//       </Link>
//     </section>
//     <section id="subheader">
//       <StaticQuery
//         query={graphql`
//           query MyQuery {
//             md: allMarkdownRemark {
//               edges {
//                 node {
//                   meta: frontmatter {
//                     title
//                     tags
//                     date
//                     menus
//                   }
//                   fields {
//                     slug
//                   }
//                 }
//               }
//             }
//           }
//         `}
//         render={data =>
//           data.md.edges.filter(({node})=>{
//             return node.meta.menus && node.meta.menus.includes('Top')
//           }).map(({ node }) => {
//             return (
//               <>
//                 <Link
//                   to={node.fields.slug}
//                   css={css`
//                     float: right;
//                     font-size: 12px;
//                   `}
//                 >
//                   {node.meta.title} &nbsp;
//                 </Link>
//                 <br />
//               </>
//             )
//           })
//         }
//       />
//     </section>
//   </>
// )
