import {
  FaFacebookF,
  FaGithub,
  FaTwitter,
  FaLinkedinIn,
  FaEnvelope,
  FaGlobe,
  FaInstagram,
} from "react-icons/fa"
import { css } from "@emotion/core"
import React from "react"
import { Content } from "rbx"
import { Link } from "gatsby"

// TODO remove this when you launch!
const poweredBy = false

const social = [
  {
    href: "https://www.linkedin.com/in/danielpatricklynch/",
    Component: FaLinkedinIn,
  },
  {
    href: "mailto:pyramation@gmail.com",
    Component: FaEnvelope,
  },
  {
    href: "https://www.crunchbase.com/person/dan-lynch-3",
    Component: FaGlobe,
  },
  {
    href: "https://twitter.com/danpatricklynch",
    Component: FaTwitter,
  },
  {
    href: "https://www.facebook.com/danlynch",
    Component: FaFacebookF,
  },
  {
    href: "https://www.instagram.com/pyramation/",
    Component: FaInstagram,
  },
  {
    href: "https://github.com/pyramation",
    Component: FaGithub,
  },
]

export default () => (
  <Content
    textAlign="centered"
    css={css`
      padding-top: 12px;
    `}
  >
    <p>
      <ul
        style={{
          marginLeft: "0px",
        }}
      >
        {social.map(({ href, Component }) => {
          return (
            <li
              css={css`
                display: inline;
              `}
            >
              <a href={href} target="blank">
                <Component />
              </a>
              &nbsp;
            </li>
          )
        })}
      </ul>
    </p>
    <p>
      © 2019 Dan Lynch · <Link to="/privacy">Privacy</Link> ·{" "}
      <Link to="/disclaimer">Disclaimer</Link>
    </p>
    {poweredBy && (
      <p
        css={css`
          font-size: 12px;
        `}
      >
        Powered by{" "}
        <a href="https://airpage.com" target="blank">
          AirPage™
        </a>
      </p>
    )}
  </Content>
)
